/* eslint-disable @typescript-eslint/camelcase */
import { TariffData, TariffEntities } from "@/domain/entities/Tariff";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { TariffPresenter } from "../presenters/TariffPresenter";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { CheckTariffRequest } from "@/data/payload/api/TariffApiRequest";
import { AccountController } from "./AccountController";
export interface TariffState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  isLoadingDownload: boolean;
  tariffData: TariffEntities;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorDownload: boolean;
  errorCause: string;
  errorCauseDownload: string;
  openModal: boolean;
  openModalSuccess: boolean;
  detailTariff: TariffData;
  isOpenDetail: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "tariff" })
class TariffStore extends VuexModule implements TariffState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isLoadingDownload = false;
  public tariffData = new TariffEntities(new Pagination(1, 10), []);
  public retailTariffData = new TariffEntities(new Pagination(1, 10), []);
  public isError = false;
  public isErrorEdit = false;
  public isErrorDownload = false;
  public errorCause = "";
  public errorCauseDownload = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = true;
  public openSuccess = false;
  public detailTariff = new TariffData();
  public isOpenDetail = false;

  // filter
  public originId = "";
  public destinationId = "";
  public length = 1;
  public height = 30;
  public width = 20;
  public grossWeight = 10;
  public commodityId = 10;
  public productType = "";
  public accountRefId = "";
  public accountType = "";
  public isNotAvailable = false;
  public isNotFound = false;
  public errorMessage = "";
  public modalSuccessDownload = false;

  @Action
  public _onGetListRetail(params: CheckTariffRequest, cache: boolean) {
    this.setLoading(true);
    const presenter = container.resolve(TariffPresenter);
    presenter
      .getListTariff(params, cache)
      .then((res: TariffEntities) => {
        this.setRetailTariffData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onGetList(params: { params: CheckTariffRequest, cache: boolean }) {
    this.setLoading(true);
    this.setRetailTariffData(new TariffEntities(new Pagination(1, 10), []));
    this.setTariffData(new TariffEntities(new Pagination(1, 10), []));
    const presenter = container.resolve(TariffPresenter);
    presenter
      .getListTariff(params.params, params.cache)
      .then((res: TariffEntities) => {
        this.setTariffData(res);
        this.setError(false);
        this.setErrorCause("");
        this.setNotFound(false);
      })
      .catch((error: any) => {
        if (error.response) {
          this.setErrorMessage(this.isAccountCountryOutsideID ? error.response.data.message.en : error.response.data.message.id);
          this.setNotAvailable(true);
          this.setNotFound(true);
        } else {
          this.setErrorCause("internet");
          this.setNotFound(false);
        }
        this.setError(true);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public downloadTariff(params: {
    commodityId: string | number;
    productType: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(TariffPresenter);
    presenter
      .downloadTariff(params.commodityId, params.productType)
      .then(() => {
        this.setModalSuccessDownload(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, this.isAccountCountryOutsideID ? "Download Failed !" : "Unduh Gagal !", () => {
            this.downloadTariff(params);
            MainAppController.closeErrorMessage();
          })
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchtariffList() {
    this.setFirstRequest(false);
    this._onGetList(
      {
        params: new CheckTariffRequest({
          originId: this.originId,
          destinationId: this.destinationId,
          length: this.length,
          height: this.height,
          width: this.width,
          grossWeight: this.grossWeight,
          commodityId: this.commodityId,
          productType: this.productType,
          accountRefId: this.accountRefId,
          accountType: this.accountType
        }), 
        cache: true
      }
    );
  }

  @Action
  public inittariff() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this._onGetList(
      {
        params: new CheckTariffRequest({
          originId: this.originId,
          destinationId: this.destinationId,
          length: this.length,
          height: this.height,
          width: this.width,
          grossWeight: this.grossWeight,
          commodityId: this.commodityId,
          productType: this.productType,
          accountRefId: this.accountRefId,
          accountType: this.accountType
        }), 
        cache: true
      }
    );
  }

  @Action
  public setFirstPage() {
    this.tariffData.pagination.page = 1;
  }

  @Action
  public selectFilterAction(params: {
    originId: string;
    destinationId: string;
    length: number;
    height: number;
    width: number;
    grossWeight: number;
    accountRefId: string;
    accountType: string;
  }) {
    this.setOriginId(params.originId);
    this.setDestinationId(params.destinationId);
    this.setLength(params.length);
    this.setHeight(params.height);
    this.setWidth(params.width);
    this.setGrossWeigth(params.grossWeight);
    this.setAccountRefId(params.accountRefId);
    this.setAccountType(params.accountType);
    this.setFirstPage();
    this.fetchtariffList();
  }

  @Mutation
  public setNotAvailable(value: boolean) {
    this.isNotAvailable = value;
  }

  @Mutation
  public setNotFound(value: boolean) {
    this.isNotFound = value;
  }

  @Mutation
  public setErrorMessage(value: string) {
    this.errorMessage = value;
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  public setLoadingDownload(isLoading: boolean) {
    this.isLoadingDownload = isLoading;
  }

  @Mutation
  public setOpenDetail(val: boolean) {
    this.isOpenDetail = val;
  }

  @Mutation
  public setDetailTarif(item: TariffData) {
    this.detailTariff = item;
  }
  @Mutation
  private setRetailTariffData(data: TariffEntities) {
    this.retailTariffData = data;
  }
  @Mutation
  private setTariffData(data: TariffEntities) {
    this.tariffData = data;
  }
  @Mutation
  public setDefaultTariffData() {
    this.tariffData = new TariffEntities(new Pagination(1, 10), []);
  }

  @Mutation
  public setModalSuccessDownload(bool: boolean) {
    this.modalSuccessDownload = bool;
  }

  @Mutation
  private setOriginId(value: string) {
    this.originId = value;
  }

  @Mutation
  private setDestinationId(value: string) {
    this.destinationId = value;
  }

  @Mutation
  private setLength(value: number) {
    this.length = value;
  }

  @Mutation
  private setHeight(value: number) {
    this.height = value;
  }

  @Mutation
  private setWidth(value: number) {
    this.width = value;
  }

  @Mutation
  private setGrossWeigth(value: number) {
    this.grossWeight = value;
  }

  @Mutation
  private setAccountRefId(value: string) {
    this.accountRefId = value;
  }

  @Mutation
  private setAccountType(value: string) {
    this.accountType = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorDownload(boolean: boolean) {
    this.isErrorDownload = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Mutation
  public setErrorCauseDownload(str: string) {
    this.errorCauseDownload = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  // state for is click filter
  public isClickFilter = false;

  @Mutation
  public setClickFilter(value: boolean) {
    this.isClickFilter = value;
  }

  get isAccountCountryOutsideID() {
    return AccountController.accountData.account_type_detail.countryCode.toLowerCase() !== 'id';
  }
}

export const TariffController = getModule(TariffStore);
