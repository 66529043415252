import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class CheckTariffRequest {
  originId = "";
  destinationId = "";
  length = 0;
  height = 0;
  width = 0;
  grossWeight = 0;
  commodityId = 0;
  productType = "";
  accountRefId = "";
  accountType = "";
  price = 0;
  constructor(fields?: Partial<CheckTariffRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
